import React from 'react'
import Img, { FluidObject } from 'gatsby-image'
import styled from '@emotion/styled'
import { rgba } from 'polished'
import Fade from 'react-reveal/Fade'
import Theme, { Mq } from '../themes/default'
import { Row, Column } from '../atoms/grid'
import { Span, Paragraph } from '../atoms/text'
import { IContentfulFeaturedSection } from '../interfaces'
import { H2, H6 } from '../atoms/heading'

interface IProps {
  featuredSections: IContentfulFeaturedSection[]
}

const FeaturedSectionWrapper = styled.div`
  padding-right: 0;
  margin: 0 auto;

  ${Mq.md} {
    margin: 5rem auto 7rem;
    padding-right: 5%;
  }
  ${Mq.xxl} {
    padding-right: 15%;
  }
`

const FeaturedSectionInnerContainer = styled.div`
  background-color: #f9f7f6;
  padding: 0% 5%;
  ${Mq.md} {
    padding: 0% 0% 0% 5.3%;
  }
  ${Mq.xxl} {
    padding-left: 17.7%;
  }
`

const FeaturedSectionText = styled.div``

const FeaturedSectionTitle = styled(H2)`
  margin-top: 1rem;
`

const FeaturedSectionParagraph = styled(Paragraph)`
  margin-top: 1rem;
`

const FeatureSectionSubtitle = styled(H6)`
  letter-spacing: 2px;

  &:after {
    content: '';
    display: block;
    width: 3rem;
    height: 2px;
    margin-top: 1rem;
    background-color: ${props => props.theme.colors.lightBlue};
  }
`

const LeftPhotoContainer = styled.div`
  width: 90%;
  height: 110%;
  position: relative;

  ${Mq.md} {
    margin-right: auto;
    width: 80%;
    height: 120%;
  }

  &:before {
    content: '';
    position: absolute;
    border: 1rem solid ${props => rgba(props.theme.colors.darkBlue, 0.1)};
    width: 17rem;
    height: 20rem;
    bottom: -0.5rem;
    left: 5rem;

    ${Mq.md} {
      left: -3rem;
      bottom: 7.5rem;
    }
    ${Mq.lg} {
      bottom: 1.5rem;
    }
    @media only screen and (min-width: 2000px) { {
      height: 26rem;
    }
  }
`

const RightPhotoContainer = styled.div`
  width: 90%;
  height: 110%;
  position: relative;
  margin-right: auto;
  ${Mq.md} {
    margin-right: 0;
    margin-left: auto;
    width: 80%;
    height: 120%;
  }

  &:before {
    content: '';
    position: absolute;
    border: 1rem solid ${props => rgba(props.theme.colors.darkBlue, 0.1)};
    width: 18rem;
    height: 26rem;
    top: -3rem;
    left: 4rem;
    ${Mq.md} {
      width: 24rem;
      left: -1rem;
    }
    @media only screen and (min-width: 2000px) { {
      top: 3rem;
    }
  }
`

const basePhotoStyles = `
  transition: ${Theme.basicTransition};
  filter: grayscale(0);
  box-shadow: 0 10px 20px 0 rgba(0,0,0,0.20);

  &:hover {
    filter: grayscale(0);
  }

  ${Mq.md} {
    filter: grayscale(1);
  }
`

const LeftLargePhoto = styled(Img)`
  ${basePhotoStyles}
  max-width: 27rem;
  margin: 0 auto;

  ${Mq.md} {
    margin-left: 0;
    margin-right: auto;
  }
  @media only screen and (min-width: 2000px) { {
    max-width: 30rem;
  }
`

const LeftTopPhoto = styled(Img)`
  ${basePhotoStyles}
  right: -3rem;
  top: -6rem;
  width: 11rem;
  position: absolute !important;
  display: none;

  ${Mq.md} {
    display: block;
    right: -5rem;
    width: 11rem;
  }
  ${Mq.lg} {
    right: -3rem;
    width: 14rem;
  }
  @media only screen and (min-width: 2000px) { {
    right: 8rem;
  }
`

const LeftBottomPhoto = styled(Img)`
  ${basePhotoStyles}
  right: 2rem;
  bottom: -1rem;
  width: 13rem;
  position: absolute !important;
  ${Mq.sm} {
    right: 0rem;
    bottom: -1rem;
    width: 18rem;
  }
  ${Mq.md} {
    bottom: 4rem;
    width: 17rem;
  }
  ${Mq.lg} {
    bottom: -1rem;
    width: 20rem;
  }
  @media only screen and (min-width: 2000px) { {
    right: 4rem;
  }
`

const RightLargePhoto = styled(Img)`
  ${basePhotoStyles}
  max-width: 27rem;
  margin: 0 auto;

  ${Mq.md} {
    margin-right: 0;
    margin-left: auto;
  }
  @media only screen and (min-width: 2000px) { {
    max-width: 30rem;
  }
`

const RightTopPhoto = styled(Img)`
  ${basePhotoStyles}
  width: 14rem;
  left: 2rem;
  top: -3rem;
  position: absolute !important;
  display: none;

  ${Mq.md} {
    display: block;
    left: -5rem;
    width: 11rem;
  }
  ${Mq.lg} {
    left: -3rem;
    width: 14rem;
  }
  @media only screen and (min-width: 2000px) { {
    left: 5rem;
  }
`

const RightBottomPhoto = styled(Img)`
  ${basePhotoStyles}
  left: 2rem;
  bottom: -2rem;
  width: 13rem;
  position: absolute !important;

  ${Mq.sm} {
    left: 2rem;
    bottom: -2rem;
    width: 18rem;
  }
  ${Mq.md} {
    bottom: -1rem;
    width: 17rem;
  }
  ${Mq.lg} {
    bottom: -2rem;
    width: 20rem;
  }
  @media only screen and (min-width: 2000px) { {
    left: 4rem;
  }
`

export const FeaturedSection = (props: IProps) => {
  const { featuredSections } = props

  return (
    <Fade left distance={Theme.fadeDistance}>
      <FeaturedSectionWrapper>
        <FeaturedSectionInnerContainer>
          <Row flexWrap="wrap" pt={5} pb={[3, 4, 5]}>
            <Column width={[1, 1, 1 / 2]}>
              <LeftPhotoContainer>
                <LeftLargePhoto fluid={featuredSections[0].squareImage.fluid} />
                <LeftTopPhoto fluid={featuredSections[0].verticalImage.fluid} />
                <LeftBottomPhoto
                  fluid={featuredSections[0].horizontalImage.fluid}
                />
              </LeftPhotoContainer>
            </Column>

            <Column
              alignSelf="center"
              mt={[5, 5, 0]}
              p={[0, 5]}
              py={[4, 5, 0]}
              width={[1, 1, 1 / 2]}
            >
              <Fade bottom distance={Theme.fadeDistance}>
                <FeaturedSectionText>
                  <FeatureSectionSubtitle className="subtitle" />
                  <FeaturedSectionTitle>
                    {featuredSections[0].title}
                  </FeaturedSectionTitle>
                  <FeaturedSectionParagraph>
                    {featuredSections[0].subtitle}
                  </FeaturedSectionParagraph>
                </FeaturedSectionText>
              </Fade>
            </Column>
          </Row>

          <Row flexWrap="wrap" pt={5} pb={[3, 4, 5]}>
            <Column
              alignSelf="center"
              mt={[5, 5, 0]}
              p={[0, 5]}
              py={[4, 5, 0]}
              order={[2, 2, 1]}
              width={[1, 1, 1 / 2]}
            >
              <Fade bottom distance={Theme.fadeDistance}>
                <FeaturedSectionText>
                  <FeatureSectionSubtitle className="subtitle" />
                  <FeaturedSectionTitle>
                    {featuredSections[1].title}
                  </FeaturedSectionTitle>
                  <FeaturedSectionParagraph>
                    {featuredSections[1].subtitle}
                  </FeaturedSectionParagraph>
                </FeaturedSectionText>
              </Fade>
            </Column>

            <Column order={[1, 1, 2]} width={[1, 1, 1 / 2]}>
              <RightPhotoContainer>
                <RightLargePhoto
                  fluid={featuredSections[1].squareImage.fluid}
                />
                <RightTopPhoto
                  fluid={featuredSections[1].verticalImage.fluid}
                />
                <RightBottomPhoto
                  fluid={featuredSections[1].horizontalImage.fluid}
                />
              </RightPhotoContainer>
            </Column>
          </Row>
        </FeaturedSectionInnerContainer>
      </FeaturedSectionWrapper>
    </Fade>
  )
}

export default FeaturedSection
