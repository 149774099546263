import React from 'react'
import Img from 'gatsby-image'
import styled from '@emotion/styled'
import { rgba } from 'polished'
import Fade from 'react-reveal/Fade'

import Theme, { Mq } from '../themes/default'
import { Row, Column } from '../atoms/grid'
import { H2, H3 } from '../atoms/heading'
import { IContentfulPressItem } from '../interfaces'

const PressSectionContainer = styled.div`
  padding: 3rem 5% 1rem;

  ${Mq.md} {
    padding: 7rem 3% 7rem;
    margin: 0 5%;
  }
  ${Mq.xxl} {
    margin: 0 15%;
  }
`

const PressSectionArticle = styled(Row)`
  padding: 2rem 0;

  &:not(:last-child) {
    border-bottom: 1px solid ${rgba(Theme.colors.darkBlue, 0.1)};
  }

  ${Mq.md} {
    padding: 3rem 0;
  }
`

const PressSectionTitleLink = styled.a`
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    color: ${props => props.theme.colors.darkBlue};
  }

  svg {
    width: 80px;
    height: 60px;

    image {
      width: 80px;
      height: 60px;
    }
  }

  ${Mq.md} {
    svg {
      width: 120px;
      height: 90px;

      image {
        width: 120px;
        height: 90px;
      }
    }
  }
`

const PressLogo = styled(Img)`
  max-width: 10rem;
`

const PressSectionTitle = styled(H3)`
  max-width: 760px;
  padding-bottom: 1.5rem;
  padding-right: 2rem;
`

const PressSectionLink = styled.a`
  font-weight: bold;
  color: ${props => props.theme.colors.primary};
  text-decoration: none;
  font-size: 0.9375rem;

  &:hover {
    text-decoration: underline;
  }
`

export const FeaturedPressSection = props => {
  const { items } = props
  return (
    <PressSectionContainer>
      <Fade bottom={true} distance={Theme.fadeDistance}>
        <Row>
          <H2>BiasSync in the press</H2>
        </Row>
      </Fade>

      <Fade bottom={true} cascade={true} distance={Theme.fadeDistance}>
        <div>
          {items.map((pressItem: IContentfulPressItem, index: number) => {
            const formattedDate = new Date(
              pressItem.publishDate,
            ).toLocaleDateString('en-US', {
              day: 'numeric',
              month: 'long',
              year: 'numeric',
            })

            return (
              <PressSectionArticle flexWrap="wrap" key={index}>
                <Column width={[1, 1, 1 / 6]} pr={[0, 0, 3]} pb={[4, 3, 0]}>
                  <PressSectionTitleLink aria-label={pressItem.link.title} href={pressItem.link.externalLink}>
                    <svg width="120" height="90" viewBox="0 0 120 90">
                      <image
                        xlinkHref={pressItem.logo.file.url}
                        href={pressItem.logo.file.url}
                        width="120"
                        height="90"
                      />
                    </svg>
                  </PressSectionTitleLink>
                </Column>

                <Column
                  flex={1}
                  order={[2, 2, 1]}
                  width={[1, 1, 4 / 6]}
                  pr={[0, 0, 4]}
                >
                  <PressSectionTitleLink href={pressItem.link.externalLink}>
                    <PressSectionTitle>{pressItem.title}</PressSectionTitle>
                  </PressSectionTitleLink>
                  <PressSectionLink href={pressItem.link.externalLink}>
                    {pressItem.publication}
                  </PressSectionLink>
                </Column>

                <Column order={[1, 1, 2]} width={[1, 1, 1 / 6]} pb={[3, 3, 0]}>
                  <span>{formattedDate}</span>
                </Column>
              </PressSectionArticle>
            )
          })}
        </div>
      </Fade>
    </PressSectionContainer>
  )
}
