import React, { useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'

interface IProps {
  time: number
  className?: string
}

const ProgressEl = styled.div`
  position: relative;
  height: 0.625rem;
`

const ProgressInner = styled.div`
  background: ${props => props.theme.colors.lightBlue};
  height: 100%;
  border-radius: inherit;
`

export const ProgressBar = (props: IProps) => {
  return (
    <ProgressEl className={props.className}>
      <ProgressInner className="progress" />
    </ProgressEl>
  )
}
