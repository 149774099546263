import React, { useState, useEffect, useRef } from 'react'
import Slider from 'react-slick'
import styled from '@emotion/styled'
import { rgba } from 'polished'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { Mq } from '../themes/default'
import { ProgressBar } from '../atoms/progress-bar'
import { Row, Column } from '../atoms/grid'
import { Span } from '../atoms/text'

const CarouselContainer = styled.div`
  padding-left: 5%;
  position: relative;

  ${Mq.xxl} {
    padding-left: 15%;
  }
`

const Slide = styled.div`
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  position: relative;
  background-color: ${props => props.theme.colors.darkBlue};
  color: ${props => props.theme.colors.white};
  min-height: 100%;
`

const InnerSlide = styled(Row)``

const Quote = styled.h4`
  color: ${props => props.theme.colors.white};
  font-size: 2rem;
  font-weight: 300;
  font-style: italic;
  margin-bottom: 1.5rem;

  ${Mq.lg} {
    font-size: 3rem;
  }
`

const Author = styled(Span)`
  font-weight: bold;
  color: ${props => rgba(props.theme.colors.white, 0.7)};
`

const AuthorTitle = styled(Span)`
  color: ${props => rgba(props.theme.colors.white, 0.5)};
`

const ProgressContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
`

const TIME = 8000

const TestimonialCarousel = (props: any) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  return (
    <CarouselContainer>
      <Slider
        arrows={false}
        dots={false}
        autoplay={true}
        autoplaySpeed={TIME}
        draggable={false}
        swipe={false}
        afterChange={index => setCurrentIndex(index)}
      >
        {props.testimonials.map((testimonial: any, index: number) => {
          return (
            <Slide key={index}>
              <InnerSlide p={[1, 3, 5]} flexWrap="wrap" alignItems="center">
                <Column width={1} p={[4, 5]}>
                  <Quote>{testimonial.quote}</Quote>
                  <Row flexWrap="wrap" my={2}>
                    <Column width={1} my={1}>
                      <Author>{testimonial.author}</Author>
                    </Column>
                    <Column width={1} my={1}>
                      <AuthorTitle>{testimonial.authorTitle}</AuthorTitle>
                    </Column>
                  </Row>
                </Column>
              </InnerSlide>
              <ProgressContainer key={index}>
                <ProgressBar
                  time={TIME}
                  className={index === currentIndex ? 'current-progress' : null}
                />
              </ProgressContainer>
            </Slide>
          )
        })}
      </Slider>
    </CarouselContainer>
  )
}

export default TestimonialCarousel
