import React, { useContext } from 'react'
import styled from '@emotion/styled'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import { rgba } from 'polished'
import { Mq } from '../themes/default'
import Theme from '../themes/default'
import Fade from 'react-reveal/Fade'

import { Row, Column } from '../atoms/grid'
import { H1, H6 } from '../atoms/heading'
import { Paragraph } from '../atoms/text'
import { Button } from '../atoms/link'
import { IContentfulHome } from '../interfaces'
import Waves from '../assets/images/waves.jpg'
import { AppContext } from '../app-context'

const WavesContainer = styled.div`
  background-image: url(${Waves});
  background-repeat: no-repeat;
  width: 100vw;
  position: absolute;
  left: 0;
  top: 0;
  height: 140%;
  background-size: cover;
  z-index: -2;
  ${Mq.md} {
    width: 102vw;
    left: -8%;
  }
  ${Mq.xxl} {
    left: -22%;
  }
`

const HeroBox = styled.div`
  position: absolute;
  z-index: -1;
  width: 75%;
  border: 10px solid ${props => rgba(props.theme.colors.lightGray, 0.5)};
  height: 80%;
  margin: 0 auto;
  top: 15%;
  left: 0;
  right: 0;

  ${Mq.md} {
    border-width: 1rem;
    height: 75%;
    top: 15%;
    width: 85%;
  }
`

const HeroRow = styled(Row)`
  padding: 15% 5% 10%;
  align-items: center;
  position: relative;

  ${Mq.md} {
    margin: 0 5%;
    padding: 10% 3% 9%;
  }

  ${Mq.xxl} {
    margin: 0 15%;
    padding: 9% 3% 8%;
  }
`

const HeroParagraph = styled(Paragraph)`
  max-width: 45rem;
`

const HeroText = styled.div`
  margin-bottom: 2.5rem;
  max-width: 900px;

  ${Mq.md} {
    margin-bottom: 4rem;
  }
`

const HeroSlogan = styled(H6)`
  &.subtitle {
    text-transform: none;
    line-height: 1.1rem;
    max-width: 10rem;
    position: absolute;
    top: 3.4rem;
  }
`

export const Hero = props => {
  const { appState, appDispatch } = useContext(AppContext)

  const {
    contentfulHomePage,
  }: { contentfulHomePage: IContentfulHome } = useStaticQuery(
    graphql`
      query HeroQuery {
        contentfulHomePage {
          heroSlogan
          heroTagline
          heroText
          heroCtaLink {
            ...Link
          }
        }
      }
    `,
  )

  return (
    <HeroRow px={50}>
      {/* <HeroSlogan className="subtitle">
      {contentfulHomePage.heroSlogan}
    </HeroSlogan> */}

      <Column>
        <WavesContainer />
        <HeroBox />

        <Fade bottom={true} distance={Theme.fadeDistance}>
          <HeroText>
            <H1>{contentfulHomePage.heroTagline}</H1>
            <HeroParagraph largeText={true}>
              {contentfulHomePage.heroText}
            </HeroParagraph>
          </HeroText>

          <Button aria-label="Go to request demo page" onClick={() => navigate('/request-demo')}>
            {contentfulHomePage.heroCtaLink.title}
          </Button>
        </Fade>
      </Column>
    </HeroRow>
  )
}
